import {AppTheme} from '../models/AppThemeModel'

export function getStorageAccessToken() {
  const storage = localStorage.getItem('session-token')
  if (storage != null) {
    const storageData = JSON.parse(storage)
    var accessToken = storageData?.accessToken
  }

  return accessToken
}
export function getMode() {
  return 'dark'
}

export const baseURL = 'https://test-accounts.medex-pharm.ba/api'
export const API_URL = 'https://testapi-accounts.medex-pharm.ba/api'
export const accessAllowOrigin = 'https://testapi-accounts.medex-pharm.ba'

// export const baseURL = 'https://test-accounts.emaq.ba/api'
// export const API_URL = 'https://testapi-accounts.emaq.ba/api'
// export const accessAllowOrigin = 'https://testapi-accounts.emaq.ba'

// export const baseURL = 'https://localapi-accounts.medex-pharm.ba:44389/api'
// export const API_URL = 'https://localapi-accounts.medex-pharm.ba:44389/api'
// export const accessAllowOrigin = 'https://localapi-accounts.medex-pharm.ba:44389'

export function getAppTheme() {
  var Theme
  if (window.location.hostname.toString().includes('emaq.ba')) {
    Theme = {
      loginLogo: '/media/logos/logo-light.svg',
      fontColor: '#37b3a3',
      buttonColor: '#37b3a3',
      title: 'Emaq',
    }
  }
  if (window.location.hostname.toString().includes('medex-pharm.ba')) {
    Theme = {
      loginLogo: '/media/logos/medexlogo.png',
      fontColor: '#7d7bff',
      buttonColor: '#7d7bff',
      title: 'Medex Pharm',
    }
  }

  return Theme
}
