import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {toAbsoluteUrl} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import {AsideUserMenu} from '../../../partials'
import {useEffect, useState} from 'react'
import {AppTheme} from '../../../../app/models/AppThemeModel'
import {getAppTheme} from '../../../../app/helpers/helpers'

const AsideDefault = () => {
  const {classes} = useLayout()
  const [theme, setTheme] = useState<AppTheme>()
  useEffect(() => {
    setTheme(getAppTheme())
  }, [])

  return (
    <div
      id='kt_aside'
      className={clsx('aside py-9', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      {/* begin::Brand */}
      <div className='aside-logo flex-column-auto px-9 mb-2' id='kt_aside_logo'>
        {/* begin::Logo */}
        <Link to='/profile/overview'>
          <img
            alt='Logo'
            src={toAbsoluteUrl(theme?.loginLogo!)}
            className={
              window.location.hostname.toString().includes('medex-pharm.ba')
                ? 'h-60px logo'
                : 'h-40px logo'
            }
          />
        </Link>
        {/* end::Logo */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div id='kt_aside_menu' className='aside-menu flex-column-fluid ps-5 pe-3 mb-9'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      <div className='aside-footer flex-column-auto px-9' id='kt_aside_footer'>
        <AsideUserMenu />
      </div>
      {/* end::Footer */}
    </div>
  )
}

export {AsideDefault}
