import axios from 'axios'
import jwt_decode from 'jwt-decode'
import dayjs from 'dayjs'
import {baseURL} from '../../app/helpers/helpers'
import {Logout} from '../../app/modules/auth/Logout'

const axiosInstance = axios.create({})

axiosInstance.defaults.withCredentials = true
axiosInstance.defaults.headers.Accept = 'application/json'

axiosInstance.interceptors.request.use(async (req) => {
  // if refresh token expired logout
  const date = new Date()
  const seconds = Math.floor(date.getTime() / 1000)
  let refreshData = localStorage.getItem('emaq-refreshToken')
  if (refreshData != null) var refreshToken = JSON.parse(refreshData)
  if (seconds > refreshToken) {
    localStorage.removeItem('persist:emaq-usersession')
    localStorage.removeItem('auth-tokens')
    window.location.href = '/auth'

    return req
  }

  // if accessToken not expired continue
  var authTokens
  let data = localStorage.getItem('session-token')
  if (data != null) authTokens = JSON.parse(data)
  req.headers.Authorization = `Bearer ${authTokens?.accessToken}`
  const user: any = jwt_decode(authTokens.accessToken)

  var isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1

  if (!isExpired) {
    return req
  }

  //if access token expired request new token
  if (isExpired) {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.accessToken}`,
        ClientId: '1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF',
      },
    }

    const refreshData = {
      accessToken: authTokens.accessToken,
      refreshToken: authTokens.refreshToken,
    }

    const response = await axios.post(`${baseURL}/usersessions/refresh`, refreshData, config)

    localStorage.removeItem('session-token')
    localStorage.setItem('session-token', JSON.stringify(response.data))
    req.headers.Authorization = `Bearer ${response.data.accessToken}`
    isExpired = false
    return req
  }
})

axiosInstance.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    const status = err.response ? err.response.status : null
    if (status == 401) {
      localStorage.removeItem('persist:emaq-usersession')
      localStorage.removeItem('auth-tokens')
      window.location.href = '/auth'
    }
    if (status == 403) {
      localStorage.removeItem('persist:emaq-usersession')
      localStorage.removeItem('auth-tokens')
      window.location.href = '/auth'
    }
    if (status == 400) {
      localStorage.removeItem('persist:emaq-usersession')
      localStorage.removeItem('auth-tokens')
      window.location.href = '/auth'
    }
    return Promise.reject(err)
  }
)

export default axiosInstance
