/* eslint-disable react/jsx-no-target-blank */
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import jwtDecode from 'jwt-decode'
import {getUserApplications} from '../../../../app/services/ApplicationServices'
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {getStorageAccessToken} from '../../../../app/helpers/helpers'
import {useIntl} from 'react-intl'

export function AsideMenuMain() {
  const intl = useIntl()
  const [apps, setApps] = useState([])
  const [hasApps, setHasApps] = useState(false)

  useEffect(() => {
    getUserApplications(getStorageAccessToken()).then((response) => {
      if (response.data.resultList != null) {
        setApps(response.data.resultList.filter((x: any) => !x.redirectUrl.includes('accounts')))
        setHasApps(true)
      } else setHasApps(false)
    })
  }, [])

  return (
    <>
      {window.innerWidth > 600 && (
        <>
          <AsideMenuItem
            to='/profile/overview'
            icon='/media/icons/duotune/general/gen001.svg'
            title={intl.formatMessage({id: 'PROFILE.HEADER_OVERVIEW'})}
            isNav={true}
          />
          <AsideMenuItem
            to='/profile/projects'
            icon='/media/icons/user.png'
            title={intl.formatMessage({id: 'PROFILE.HEADER_USER_PROFILE'})}
            isNav={true}
          />
          <AsideMenuItem
            to='/profile/orders'
            icon='/media/icons/duotune/ecommerce/ecm005.svg'
            title={intl.formatMessage({id: 'PROFILE.HEADER_ORDERS'})}
            isNav={true}
          />
        </>
      )}
      {hasApps && apps.length > 1 && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Aplikacije</span>
          </div>
        </div>
      )}

      {hasApps &&
        apps.map((app: any) => {
          return (
            <AsideMenuItem
              key={app.id}
              to={
                app.redirectUrl.split('.').length - 1 >= 2
                  ? app.redirectUrl
                  : app.redirectUrl
              }
              isNav={false}
              title={app.name}
              icon={toAbsoluteUrl(app.resourceUrl)}
              isAction={true}
            ></AsideMenuItem>
          )
        })}

      {!hasApps && <p> {intl.formatMessage({id: 'GENERAL.APPLICATIONS_LOADING'})}</p>}
    </>
  )
}
