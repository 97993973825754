/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik, validateYupSchema} from 'formik'
import {updateSecurityInformation} from '../../../services/UserServices'
import {getAppTheme, getStorageAccessToken} from '../../../helpers/helpers'
import {RootState} from '../../../../setup'
import {UserModel} from '../../auth/models/UserModel'
import store from '../../../../setup/redux/Store'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {AppTheme} from '../../../models/AppThemeModel'

export function SecurityInformation() {
  const intl = useIntl()
  const SecurityInformationSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${intl.formatMessage({id: 'VALIDATOR.FORMAT_EMAIl'})}`)
      .min(
        8,
        `${intl.formatMessage({id: 'VALIDATOR.MINIMUM'})} 8 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .max(
        30,
        `${intl.formatMessage({id: 'VALIDATOR.MAXIMUM'})}30 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .required(`${intl.formatMessage({id: 'VALIDATOR.FORMAT_EMAIlREQUIRED'})}`),
    password: Yup.string()
      .min(
        8,
        `${intl.formatMessage({id: 'VALIDATOR.MINIMUM'})} 8 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .max(
        15,
        `${intl.formatMessage({id: 'VALIDATOR.MAXIMUM'})}15 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .required(`${intl.formatMessage({id: 'VALIDATOR.PASSWORD_PWREQUIRED'})}`),
    passwordConfirmation: Yup.string()
      .required(`${intl.formatMessage({id: 'VALIDATOR.PASSWORD_CONFIRM'})}`)
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          `${intl.formatMessage({id: 'VALIDATOR.PASSWORD_MISSMATCH'})}`
        ),
      }),
  })
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const initialValues = {
    email: user.email,
    password: '',
    passwordConfirmation: '',
  }
  const dispatch = useDispatch()
  const [theme, setTheme] = useState<AppTheme>()
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: SecurityInformationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      var securityInfo = {
        email: values.email,
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
      }
      updateSecurityInformation(securityInfo, getStorageAccessToken())
        .then((response) => {
          var usr = store.getState()
          handleCloseModal()
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    },
  })

  useEffect(() => {
    setTheme(getAppTheme())
  }, [])

  function handleCloseModal() {
    setShow(true)
    setTimeout(() => {
      setShow(false)
      localStorage.removeItem('persist:emaq-usersession')
      localStorage.removeItem('auth-tokens')
      window.location.href = '/auth'
    }, 3000)
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>
            {intl.formatMessage({id: 'USERPROFILE.SECURITY_TITLE'})}
          </h3>
        </div>
      </div>
      <div id='kt_account_settings_profile_details' className='collapse show'>
        <form
          id='kt_account_profile_security_form'
          className='form'
          onSubmit={formik.handleSubmit}
          noValidate
          encType='multipart/form-data'
        >
          <div className='card-body  p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>
                  {intl.formatMessage({id: 'USERPROFILE.SECURITY_EMAIL'})}
                </span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='email'
                  name='email'
                  autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>
                  {intl.formatMessage({id: 'USERPROFILE.SECUTIRY_PASSWORD'})}
                </span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  placeholder={intl.formatMessage({id: 'USERPROFILE.SECUTIRY_PASSWORD'})}
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.password && formik.errors.password},
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                  type='password'
                  name='password'
                  autoComplete='off'
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>
                  {intl.formatMessage({id: 'USERPROFILE.SECUTIRY_PASSWORD_CONFIRM'})}
                </span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  placeholder={intl.formatMessage({id: 'USERPROFILE.SECUTIRY_PASSWORD_CONFIRM'})}
                  {...formik.getFieldProps('confirmPassword')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid':
                        formik.touched.passwordConfirmation && formik.errors.passwordConfirmation,
                    },
                    {
                      'is-valid':
                        formik.touched.passwordConfirmation && !formik.errors.passwordConfirmation,
                    }
                  )}
                  type='password'
                  name='passwordConfirmation'
                  autoComplete='off'
                />
                {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.passwordConfirmation}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='reset' className='btn btn-light btn-active-light-primary me-2'>
              {intl.formatMessage({id: 'GENERAL.CANCEL'})}
            </button>
            <button
              type='submit'
              className='btn '
              style={{backgroundColor: `${theme?.fontColor!}`, color: 'white'}}
              id='kt_account_profile_security_submit'
            >
              {!loading && (
                <span className='indicator-label'>
                  {' '}
                  {intl.formatMessage({id: 'GENERAL.SAVECHANGES'})}
                </span>
              )}
              {loading && (
                <span className='indicator-label'>
                  Pričekajte..
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>

      <Modal show={show}>
        <Modal.Header>
          <Modal.Title> {intl.formatMessage({id: 'CHANGEDINFO.TITLE'})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{intl.formatMessage({id: 'CHANGEDINFO.TEXT'})}</Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  )
}
