import React, {useEffect, useState} from 'react'
import {getUserActiveSessions} from '../../../services/UserServices'
import {getStorageAccessToken} from '../../../helpers/helpers'
import {useIntl} from 'react-intl'
import '../style/Table.css'

function getDate() {
  var date = new Date()
  return date.getHours() + ' : ' + date.getMinutes()
}
export function Sessions() {
  const intl = useIntl()
  const [mobileClass, setMobileClass] = useState('')
  const [mobileClassCol, setMobileClassCol] = useState('d-flex align-items-center')

  const [sessions, setSessions] = useState([
    {
      applicationName: ' ',
      clientName: ' ',
      ipAddress: ' ',
      isCurrentSession: '',
    },
  ])
  const [loading, setLoding] = useState(true)
  useEffect(() => {
    if (window.innerWidth < 600) {
      setMobileClass('d-flex justify-content-between noBorder mt-5')

      setMobileClassCol('d-flex align-items-center col-5')
    }
    getUserActiveSessions(getStorageAccessToken())
      .then((response: any) => {
        setSessions(response.data.sessions)
        setLoding(false)
      })
      .catch((err: any) => {
        console.log(err)
      })
    return () => {
      setSessions([])
    }
  }, [])

  return (
    <>
      {!loading && (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header border-0 pt-5'>
            <div>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  {intl.formatMessage({id: 'SESSIONS.TITLE'})}
                </span>
              </h3>
            </div>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-solid table-row-bordered align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='min-w-250px'>
                      {intl.formatMessage({id: 'SESSIONS.TABLE_APPLICATIONS'})}
                    </th>
                    <th className='min-w-150px'>
                      {intl.formatMessage({id: 'SESSIONS.TABLE_CLIENTS'})}
                    </th>
                    <th className='min-w-150px'>
                      {intl.formatMessage({id: 'SESSIONS.TABLE_IPADDRESS'})}
                    </th>
                    <th className='min-w-150px'>
                      {intl.formatMessage({id: 'SESSIONS.TABLE_TIME'})}
                    </th>
                    <th className='min-w-100px'>
                      {intl.formatMessage({id: 'SESSIONS.TABLE_STATUS'})}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sessions?.map((session: any, index) => {
                    return (
                      <tr key={index}>
                        <td className={mobileClass}>
                          <label className='lbl'>
                            {intl.formatMessage({id: 'SESSIONS.TABLE_APPLICATIONS'})}
                          </label>

                          <div className={mobileClassCol}>
                            <div className='d-flex justify-content-start flex-column'>
                              <p className='text-dark  d-block fs-6'>{session.applicationNames}</p>
                            </div>
                          </div>
                        </td>
                        <td className={mobileClass}>
                          <label className='lbl'>
                            {intl.formatMessage({id: 'SESSIONS.TABLE_CLIENTS'})}
                          </label>

                          <div className={mobileClassCol}>
                            <div className='d-flex justify-content-start flex-column'>
                              <p className='text-dark  d-block fs-6'>{session.clientName}</p>
                            </div>
                          </div>
                        </td>

                        <td className={mobileClass}>
                          <label className='lbl'>
                            {intl.formatMessage({id: 'SESSIONS.TABLE_IPADDRESS'})}
                          </label>

                          <div className={mobileClassCol}>
                            <div className='d-flex justify-content-start flex-column'>
                              <p className='text-dark  d-block fs-6'>{session.ipAddress}</p>
                            </div>
                          </div>
                        </td>

                        <td className={mobileClass}>
                          <label className='lbl'>
                            {intl.formatMessage({id: 'SESSIONS.TABLE_TIME'})}
                          </label>

                          <div className={mobileClassCol}>
                            <div className='d-flex justify-content-start flex-column'>
                              <p className='text-dark  d-block fs-6'>{session.createdOn}</p>
                            </div>
                          </div>
                        </td>

                        <td className={mobileClass}>
                          <label className='lbl'>
                            {intl.formatMessage({id: 'SESSIONS.TABLE_STATUS'})}
                          </label>

                          <div className={mobileClassCol} style={{marginBottom: '12px'}}>
                            {session.isCurrentSession == true && (
                              <span
                                className='badge badge-success fs-7 fw-bolder'
                                style={{backgroundColor: '#36b3a2', color: 'white'}}
                              >
                                {intl.formatMessage({id: 'SESSIONS.TABLE_STATUS_ACTIVE'})}
                              </span>
                            )}
                            {session.isCurrentSession == false && (
                              <span className='badge badge-danger fs-7 fw-bolder'>
                                {intl.formatMessage({id: 'SESSIONS.TABLE_STATUS_UNACTIVE'})}
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
