import axiosCommerceInstance from '../../setup/axios/axiosCommerceInstance'

const API_URL = 'https://devapi-commerce.emaq.ba/api'
const accessAllowOrigin = 'https://devapi-commerce.emaq.ba'

export const GET_USER_ORDERS = `${API_URL}/Orders`
export const GET_USER_ORDERS_DETAILS = `${API_URL}/Orders`
export const GET_USER_POINTS = `${API_URL}/PointsLogs`
export async function getUserOrders(data: any, pageSize: any, page: number) {
  var user = JSON.parse(data.user)
  const config = {
    headers: {
      'Access-Control-Allow-Origin': `${accessAllowOrigin}`,
    },
  }

  // return await axiosCommerceInstance.get(`${GET_USER_ORDERS}?ExtUserId=1&Page=${page}&PageSize=${pageSize}&AdditionalData.IncludeList=Store&IncludeCount=true`,config);
  return await axiosCommerceInstance.get(
    `${GET_USER_ORDERS}/GetOrderDetails?UserId=${user.userId}&page=${page}&pageSize=${pageSize}`,
    config
  )
}

export async function getUserOrdersDetails(id: number) {
  const config = {
    headers: {
      'Access-Control-Allow-Origin': `${accessAllowOrigin}`,
    },
  }

  return await axiosCommerceInstance.get(
    `${GET_USER_ORDERS_DETAILS}/${id}?IncludeList=OrderStatus&IncludeList=OrderStatus.OrderStatusTranslations&IncludeList=OrderItems&IncludeList=Store&IncludeList=ShippingAddress&IncludeList=PaymentType&IncludeList=PointsLogs&IncludeList=PaymentType.PaymentTypeTranslations&IncludeList=OrderItems.Item.ItemAttributes&IncludeList=OrderItems.Item.Images&IncludeList=OrderItems.Item.ItemTranslations&IncludeList=OrderItems.Item.Category&IncludeList=OrderItems.Item.Category.CategoryTranslations`,
    config
  )
}

export async function getUserPoints(data: any) {
  var user = JSON.parse(data.user)
  const config = {
    headers: {
      'Access-Control-Allow-Origin': `${accessAllowOrigin}`,
    },
  }

  return await axiosCommerceInstance.get(`${GET_USER_POINTS}?UserId=${user.userId}`, config)
}
