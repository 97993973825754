import {useEffect, useState} from 'react'
import {getUserOrders} from '../../../services/OrdersServices'
import {OrderDetails} from './OrderDetails'
import {useIntl} from 'react-intl'
import {OrdersModel} from '../../../models/OrdersModel'
import '../style/Table.css'
import {DEFAULT_MAX_VERSION} from 'tls'

export function Orders() {
  const [pageSize, setPageSize] = useState(window.innerWidth < 600 ? 5 : 10)
  const [page, setPage] = useState(0)
  const [showDetails, setShowDetails] = useState(false)
  const [order, setOrder] = useState()
  const [maxPage, setMaxPage] = useState(0)
  const [pages, setPages] = useState([0])
  const [noOrder, setNoOrder] = useState(false)
  const intl = useIntl()
  const [orderSubtitle, setOrderSubtitle] = useState('')
  const [hasData, setHasData] = useState(false)
  const [orders, setOrders] = useState<OrdersModel[]>([])
  const [mobileClass, setMobileClass] = useState('')
  const [mobileClassCol, setMobileClassCol] = useState('d-flex align-items-center')
  function calculatePages(pages: any) {
    setMaxPage(pages)

    var testNmb = pages % pageSize
    var nmbOfPages = 0
    if (testNmb > 0) {
      var nmb = pages / pageSize
      nmbOfPages = Math.ceil(nmb)
    } else if (testNmb == 0) {
      var nmb = pages / pageSize
      nmbOfPages = Math.round(nmb)
    }

    var helper = new Array()
    if (nmbOfPages == 1) {
      helper.push(1)
      setPages(helper)
      return
    }
    if (nmbOfPages == 2) {
      helper.push(nmbOfPages - 1)
      helper.push(nmbOfPages)
      setPages(helper)
      return
    }
    if (nmbOfPages == 3) {
      helper.push(1)
      helper.push(nmbOfPages - 1)
      helper.push(nmbOfPages)
      setPages(helper)
      return
    }
    if (nmbOfPages > 3) {
      for (let index = page; index <= nmbOfPages; index++) {
        if (index == 0) {
          helper.push(index + 1)
          helper.push(index + 2)
          helper.push(index + 3)
          setPages(helper)
          return
        }
        if (index == 1) {
          helper.push(index)
          helper.push(index + 1)
          helper.push(index + 2)
          setPages(helper)
          return
        }
        if (index > 1 && index + 1 < nmbOfPages) {
          helper.push(index)
          helper.push(index + 1)
          helper.push(index + 2)
          setPages(helper)
          return
        }

        if (index == maxPage) {
          helper.push(index - 2)
          helper.push(index - 1)
          helper.push(index)
          setPages(helper)
          return
        }
      }
    }
  }

  function orderSubtitleHandler(ordersCount: any) {
    if (ordersCount == 10) {
      setOrderSubtitle(intl.formatMessage({id: 'ORDERS.TITLE_SUBTITLE-5-10'}))
      return
    }
    if (ordersCount.toString().includes(1) && ordersCount < 10) {
      setOrderSubtitle(intl.formatMessage({id: 'ORDERS.TITLE_SUBTITLE-1'}))
      return
    }
    if (ordersCount.toString().includes(1) && ordersCount >= 20) {
      setOrderSubtitle(intl.formatMessage({id: 'ORDERS.TITLE_SUBTITLE-1'}))
      return
    }
    if (ordersCount.toString().includes(2) && ordersCount == 20) {
      setOrderSubtitle(intl.formatMessage({id: 'ORDERS.TITLE_SUBTITLE-5-10'}))
      return
    }

    if (ordersCount.toString().includes(1) && ordersCount != 10 && ordersCount < 10) {
      setOrderSubtitle(intl.formatMessage({id: 'ORDERS.TITLE_SUBTITLE-1'}))
    }
    if (
      (ordersCount.toString().includes(2) ||
        ordersCount.toString().includes(3) ||
        ordersCount.toString().includes(4)) &&
      ordersCount < 10
    ) {
      setOrderSubtitle(intl.formatMessage({id: 'ORDERS.TITLE_SUBTITLE-2-4'}))
    }
    if (
      (ordersCount.toString().includes(2) ||
        ordersCount.toString().includes(3) ||
        ordersCount.toString().includes(4)) &&
      ordersCount > 10 &&
      ordersCount < 15
    ) {
      setOrderSubtitle(intl.formatMessage({id: 'ORDERS.TITLE_SUBTITLE-5-10'}))
    }
    if (
      (ordersCount.toString().includes(2) ||
        ordersCount.toString().includes(3) ||
        ordersCount.toString().includes(4)) &&
      ordersCount > 20
    ) {
      setOrderSubtitle(intl.formatMessage({id: 'ORDERS.TITLE_SUBTITLE-2-4'}))
      return
    } else if (
      ordersCount.toString().includes(5) ||
      ordersCount.toString().includes(6) ||
      ordersCount.toString().includes(7) ||
      ordersCount.toString().includes(8) ||
      ordersCount.toString().includes(9)
    ) {
      setOrderSubtitle(intl.formatMessage({id: 'ORDERS.TITLE_SUBTITLE-5-10'}))
    }
  }

  useEffect(() => {
    if (window.innerWidth < 600) {
      setMobileClass('d-flex justify-content-between noBorder mt-5')
      setMobileClassCol('d-flex align-items-center col-5')
    }
    setTimeout(() => {
      var data = localStorage.getItem('persist:emaq-usersession')
      var userData = JSON.parse(data!)
      getUserOrders(userData, pageSize, page)
        .then((response) => {
          if (response.data.orders.length == 0) {
            setNoOrder(true)
          } else setNoOrder(false)
          setOrders(response.data.orders)
          orderSubtitleHandler(response.data.ordersCount)
          calculatePages(response.data.ordersCount)
          if (response.data.orders.length >= 1) setHasData(true)
        })
        .catch((err) => {
          console.log(err)
        })
    }, 200)

    return () => {
      setOrders([])
      setHasData(false)
      setNoOrder(false)
    }
  }, [page])

  function showOrderDetails() {
    setShowDetails(true)
  }
  function changePageSize(e: any) {
    setPageSize(e.target.value)
  }
  function setPageHandler(page: any) {
    setPage(page)
  }

  function hasDecimal(num: any) {
    return !!(num % 1)
  }

  function nextPage() {
    var p = page

    var nmb: any = maxPage / pageSize
    var nmbOfPages = Math.ceil(nmb)
    if (p + 1 < nmbOfPages) {
      setPage(p + 1)
    } else if (p >= nmbOfPages) {
      setPage(p)
    }

    return
  }

  function previousPage() {
    var p = page
    if (p > 0) {
      setPage(p - 1)
    } else {
      setPage(p)
    }
  }
  function pageHandler(e: any) {
    setPage(e.target.text - 1)
  }

  function handler(newState: boolean) {
    setShowDetails(newState)
  }

  function modalHandler(order: any) {
    setShowDetails(true)

    setOrder(order)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 pt-5'>
          <div>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>
                {intl.formatMessage({id: 'ORDERS.TITLE'})}
              </span>
              <span className='text-muted fw-bold fs-7'>
                {maxPage > 0 ? maxPage : ''} {orderSubtitle}
              </span>
            </h3>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-solid table-row-bordered align-middle gs-0 gy-4 '>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-120px'>{intl.formatMessage({id: 'ORDERS.TABLE_ORDERS'})}</th>
                  <th className='min-w-80px' style={{width: '15%'}}>
                    {intl.formatMessage({id: 'ORDERS.TABLE_STORE'})}
                  </th>
                  <th className='min-w-100px text-center '>
                    {intl.formatMessage({id: 'ORDERS.TABLE_STATUS'})}
                  </th>
                  <th className='min-w-100px'>
                    {intl.formatMessage({id: 'ORDERS.TABLE_PAYMENT_TYPE'})}
                  </th>
                  <th className='min-w-100px'>{intl.formatMessage({id: 'ORDERS.PICKUP_TYPE'})}</th>
                  <th className='min-w-100px'>{intl.formatMessage({id: 'ORDERS.TABLE_TOTAL'})}</th>
                </tr>
              </thead>

              {noOrder && (
                <tbody>
                  <tr>
                    <td colSpan={6}>
                      <div className='card mb-5 mb-xl-10'>
                        <div className='card-body py-3 margl'>
                          <div className='d-flex justify-content-center flex-column align-items-center'>
                            {window.innerWidth < 600 && (
                              <img
                                className='mt-5'
                                style={{height: '75%', width: '75%'}}
                                src='/media/background/emptyCart.svg'
                              ></img>
                            )}
                            {window.innerWidth > 600 && (
                              <img
                                className='mt-5'
                                style={{height: '50%', width: '20%'}}
                                src='/media/background/emptyCart.svg'
                              ></img>
                            )}

                            <p className='fw fs-5 mb-1 mt-6'>
                              <span style={{color: 'black'}}>
                                {intl.formatMessage({id: 'ORDERS.NOORDER_TEXT'})}{' '}
                              </span>

                              <a style={{fontWeight: 'bold'}} href='https://medex-pharm.ba/'>
                                {intl.formatMessage({id: 'ORDERS.NOORDER_TEXT_HERE'})}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
              {!hasData && !noOrder && (
                <tbody>
                  <tr>
                    <td colSpan={6}>
                      <div className='card mb-5 mb-xl-10'>
                        <div className='card-body py-3'>
                          <div className='d-flex justify-content-center flex-column align-items-center'>
                            {window.innerWidth < 600 && (
                              <img
                                className='mt-5'
                                style={{height: '35%', width: '35%', marginLeft: '100px'}}
                                src='/media/background/spinnerTable.gif'
                              ></img>
                            )}
                            {window.innerWidth > 600 && (
                              <img
                                className='mt-5'
                                style={{height: '20%', width: '5%'}}
                                src='/media/background/spinnerTable.gif'
                              ></img>
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
              {hasData && orders.length > 0 && (
                <tbody>
                  {orders.map((order) => {
                    return (
                      <tr key={order.id}>
                        <td className={mobileClass}>
                          <label className='lbl'>
                            {intl.formatMessage({id: 'ORDERS.TABLE_ORDERS'})}
                          </label>

                          <div className={mobileClassCol}>
                            <div className='d-flex justify-content-start flex-column'>
                              <a
                                className='text-dark fw-bolder text-hover-primary fs-6'
                                style={{cursor: 'pointer'}}
                                onClick={() => modalHandler(order)}
                              >
                                {order.orderNumber}
                              </a>
                              <span className='text-muted fw-bold text-muted d-block fs-7'>
                                {new Date(order['createdOn']).toLocaleString(`fr-CH`, {
                                  timeStyle: 'short',
                                  dateStyle: 'short',
                                })}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td className={mobileClass}>
                          <label className='lbl' style={{paddingLeft: '0rem'}}>
                            {intl.formatMessage({id: 'ORDERS.TABLE_STORE'})}
                          </label>

                          <div className={mobileClassCol}>
                            <div className='d-flex justify-content-start flex-column'>
                              <div className='d-flex justify-content-start flex-column'>
                                <a className='text-dark fw-bolder text-hover-primary fs-6'>
                                  {order.store.name}
                                </a>
                                <span className='text-muted fw-bold text-muted d-block fs-7'>
                                  {order.store.address}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className={mobileClass} style={{border: 'none'}}>
                          <label className='lbl'>
                            {intl.formatMessage({id: 'ORDERS.TABLE_STATUS'})}
                          </label>
                          <div
                            className={
                              window.innerWidth > 500
                                ? mobileClassCol + ' justify-content-center'
                                : mobileClassCol
                            }
                          >
                            <div
                              className='d-flex justify-content-start flex-column  '
                              style={{border: 'none'}}
                            >
                              <p className='text-dark  d-block fs-6  '>
                                <span
                                  className='badge '
                                  style={{backgroundColor: order.orderStatus.statusColor}}
                                >
                                  {
                                    order.orderStatus.orderStatusTranslations.find(
                                      (x) => x.languageId == 1
                                    )?.name
                                  }
                                </span>
                              </p>
                            </div>
                          </div>
                        </td>

                        <td className={mobileClass}>
                          <label className='lbl'>
                            {intl.formatMessage({id: 'ORDERS.TABLE_PAYMENT_TYPE'})}
                          </label>

                          <div className={mobileClassCol}>
                            <div className='d-flex justify-content-start flex-column'>
                              <p className='text-dark  d-block fs-6'>
                                {
                                  order.paymentType?.paymentTypeTranslations.find(
                                    (x) => x.languageId == 1
                                  )?.name
                                }
                              </p>
                            </div>
                          </div>
                        </td>

                        <td className={mobileClass}>
                          <label className='lbl'>
                            {intl.formatMessage({id: 'ORDERS.PICKUP_TYPE'})}
                          </label>

                          <div className={mobileClassCol}>
                            <div className='d-flex justify-content-start flex-column'>
                              {order.isPickupInStore == true && (
                                <p className='text-dark  fs-6'>
                                  {intl.formatMessage({id: 'ORDERS.PICKUP_STORE'})}
                                </p>
                              )}
                              {order.isPickupInStore == false && (
                                <p className='text-dark   fs-6'>
                                  {intl.formatMessage({id: 'ORDERS.PICKUP_DELIVERY'})}
                                </p>
                              )}
                            </div>
                          </div>
                        </td>

                        <td className={mobileClass}>
                          <label className='lbl'>
                            {intl.formatMessage({id: 'ORDERS.TABLE_TOTAL'})}
                          </label>

                          <div className={mobileClassCol}>
                            <div
                              className='d-flex justify-content-start flex-column'
                              style={{marginBottom: '8px'}}
                            >
                              <a className='text-dark fw-bolder  fs-6'>
                                {(order.orderTotal + order.shippingCosts).toFixed(2)} KM
                              </a>
                              {/* <span className='text-muted fw-bold text-muted d-block fs-7'>
                                {order.pointsLogs.length > 0
                                  ? order.pointsLogs[0].points > 0
                                    ? +order.pointsLogs[0].points + ' bodova'
                                    : ' '
                                  : ' '}
                              </span> */}
                              {order.pointsLogs && (
                                <span className='text-muted fw-bold text-muted d-block fs-7'>
                                  {order.orderTotal.toFixed(2) + 'KM'}
                                </span>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              )}
            </table>
            {pages.length > 1 && (
              <div className='row col-12 '>
                <div className='col-12 d-flex justify-content-end mb-5 '>
                  <ul className='pagination'>
                    <li className='page-item' onClick={previousPage}>
                      <a className='page-link' aria-label='Previous' style={{cursor: 'pointer'}}>
                        <span aria-hidden='true'>
                          &laquo; {intl.formatMessage({id: 'ORDERS.TABLE_ACTIONS_PREVIOUS'})}
                        </span>
                      </a>
                    </li>
                    {pages.map((pageNumber: any, key) => {
                      return (
                        <li
                          key={key}
                          className='page-item '
                          value={pageNumber}
                          onClick={pageHandler}
                        >
                          {pageNumber == page + 1 && (
                            <a
                              className='page-link'
                              style={{
                                color: 'white',
                                backgroundColor: '#009ef7',
                                cursor: 'pointer',
                              }}
                            >
                              {pageNumber}
                            </a>
                          )}
                          {pageNumber != page + 1 && (
                            <a className='page-link ' style={{cursor: 'pointer'}}>
                              {pageNumber}
                            </a>
                          )}
                        </li>
                      )
                    })}

                    <li className='page-item' onClick={nextPage}>
                      <a className='page-link' aria-label='Next' style={{cursor: 'pointer'}}>
                        <span aria-hidden='true'>
                          {intl.formatMessage({id: 'ORDERS.TABLE_ACTIONS_NEXT'})}
                          &raquo;
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {showDetails && (
              <OrderDetails showModal={showDetails} handler={handler} order={order}></OrderDetails>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
