import {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {getUserAddresses, removeAddress} from '../../../services/AddressesServices'
import {AddAddress} from './AddAddress'
import {EditAddress} from './EditAddress'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'

export function Addresses(props: any) {
  const [addressCount, setAddressCount] = useState(0)
  const [isEditAddress, setIsEditAddress] = useState(props.isEditAddress == 0 ? null : false)
  const [hasAddresses, setHasAddresses] = useState(false)
  const [addressSubtitle, setAddressSubtitle] = useState('')
  const [noAddresses, setNoAddresses] = useState(false)
  const [mobileClassCol, setMobileClassCol] = useState('d-flex align-items-center')
  const intl = useIntl()
  const [mobileClass, setMobileClass] = useState('')

  const [editAddress, setEditAddress] = useState({
    id: 0,
    addressType: 0,
    streetName: '',
    streetNumber: '',
    zipPostalCode: '',
    cityId: '',
    countryId: '',
    hasLift: '',
    floor: '',
    city: {
      name: '',
    },
    country: {
      name: '',
    },
  })
  const [addresses, setAddresses] = useState([
    {
      id: 0,
      addressType: 0,
      streetName: '',
      streetNumber: '',
      zipPostalCode: '',
      hasLift: '',
      cityId: '',
      countryId: '',
      floor: '',
      city: {
        name: '',
      },
      country: {
        name: '',
      },
    },
  ])
  function addressSubtitleHandler(addressCount: any) {
    if (addressCount == 10) {
      setAddressSubtitle(intl.formatMessage({id: 'ADRESSES.COUNT_TEXT-1'}))
      return
    }
    if (addressCount.toString().includes(1) && addressCount < 10) {
      setAddressSubtitle(intl.formatMessage({id: 'ADRESSES.COUNT_TEXT-1'}))
      return
    }
    if (addressCount.toString().includes(1) && addressCount > 20) {
      setAddressSubtitle(intl.formatMessage({id: 'ADRESSES.COUNT_TEXT-1'}))
      return
    }

    if (addressCount.toString().includes(1) && addressCount != 10 && addressCount < 10) {
      setAddressSubtitle(intl.formatMessage({id: 'ADRESSES.COUNT_TEXT-1'}))
    }
    if (
      (addressCount.toString().includes(2) ||
        addressCount.toString().includes(3) ||
        addressCount.toString().includes(4)) &&
      addressCount < 10
    ) {
      setAddressSubtitle(intl.formatMessage({id: 'ADRESSES.COUNT_TEXT-2-4'}))
    }
    if (
      (addressCount.toString().includes(2) ||
        addressCount.toString().includes(3) ||
        addressCount.toString().includes(4)) &&
      addressCount > 10 &&
      addressCount < 15
    ) {
      setAddressSubtitle(intl.formatMessage({id: 'ADRESSES.COUNT_TEXT-1'}))
    }
    if (
      (addressCount.toString().includes(2) ||
        addressCount.toString().includes(3) ||
        addressCount.toString().includes(4)) &&
      addressCount > 20
    ) {
      setAddressSubtitle(intl.formatMessage({id: 'ADRESSES.COUNT_TEXT-2-4'}))
      return
    } else if (
      addressCount.toString().includes(5) ||
      addressCount.toString().includes(6) ||
      addressCount.toString().includes(7) ||
      addressCount.toString().includes(8) ||
      addressCount.toString().includes(9)
    ) {
      setAddressSubtitle(intl.formatMessage({id: 'ADRESSES.COUNT_TEXT-1'}))
    }
  }
  function removeAddressHandler(id: any) {
    removeAddress(id)
      .then((response) => {
        var previousState = addressCount
        setAddressCount(previousState - 1)
      })
      .catch((err) => console.log(err))
  }
  function addAddress() {
    var previousState = addressCount
    setAddressCount(previousState + 1)
  }
  function clearEdit(isEdit: any) {
    setIsEditAddress(isEdit)
  }
  function editAddressHandler(address: any) {
    setEditAddress(address)
    setIsEditAddress(true)
  }

  useEffect(() => {
    if (window.innerWidth < 600) {
      setMobileClass('d-flex justify-content-between noBorder mt-5')

      setMobileClassCol('d-flex align-items-center col-5')
    }
    setTimeout(() => {
      var data = localStorage.getItem('persist:emaq-usersession')
      var userData = JSON.parse(data!)
      getUserAddresses(userData)
        .then((response) => {
          setAddresses(response.data.resultList)
          setAddressCount(response.data.resultList.length)
          addressSubtitleHandler(response.data.resultList.length)
          if (response.data.resultList.length >= 1) setNoAddresses(true)
          else setNoAddresses(true)
          if (response.data.resultList.length == 0) {
            setHasAddresses(false)
          } else setHasAddresses(true)
        })
        .catch((err) => {
          console.log(err)
        })
    }, 200)
    return () => {
      setAddresses([])
    }
  }, [addressCount])

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='addresses'>
        <div className='card-header border-0 pt-5'>
          <div>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>
                {intl.formatMessage({id: 'ADDRESSES.TITLE'})}
              </span>
              <span className='text-muted fw-bold fs-7'>
                {addressCount > 0 ? addressCount : ''} {addressSubtitle}
              </span>
            </h3>
          </div>
        </div>
        {isEditAddress && isEditAddress != null && addresses.length > 0 && (
          <EditAddress
            editAddress={editAddress}
            addressHandler={addAddress}
            editHandler={clearEdit}
          ></EditAddress>
        )}
        {!isEditAddress && isEditAddress != null && addresses.length == 0 && (
          <AddAddress addressHandler={addAddress}></AddAddress>
        )}
        {!isEditAddress && isEditAddress != null && addresses.length > 0 && (
          <AddAddress addressHandler={addAddress}></AddAddress>
        )}

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-solid table-row-bordered align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-120px'>
                    {intl.formatMessage({id: 'ADDRESSES.TABLE_STREET'})}
                  </th>
                  <th className='min-w-80px text-center '>
                    {intl.formatMessage({id: 'ADDRESSES.TABLE_FLOOR'})}
                  </th>
                  <th className='min-w-100px text-center '>
                    {intl.formatMessage({id: 'ADDRESSES.TABLE_LIFT'})}
                  </th>
                  <th className='min-w-80px '>
                    {intl.formatMessage({id: 'ADDRESSES.TABLE_CITY'})}
                  </th>
                  <th className='min-w-100px text-center'>
                    {intl.formatMessage({id: 'ADDRESSES.TABLE_ZIP'})}
                  </th>
                  <th className='min-w-100px'>
                    {intl.formatMessage({id: 'ADDRESSES.TABLE_STATE'})}
                  </th>
                </tr>
              </thead>

              {!hasAddresses && noAddresses && (
                <tbody>
                  <tr>
                    <td colSpan={6}>
                      <div className='card mb-5 mb-xl-10'>
                        <div className='card-body py-3 margl'>
                          <div className='d-flex justify-content-center flex-column align-items-center'>
                            {window.innerWidth > 600 && (
                              <img
                                className='mt-5'
                                style={{height: '50%', width: '20%'}}
                                src='/media/background/addressIlustrations.svg'
                              ></img>
                            )}
                            {window.innerWidth < 600 && (
                              <img
                                className='mt-5'
                                style={{height: '75%', width: '75%'}}
                                src='/media/background/addressIlustrations.svg'
                              ></img>
                            )}

                            <p className='fw fs-5 mb-1 mt-6 '>
                              <Link
                                className={
                                  `nav-link text-active-primary me-6 textW ` +
                                  (window.location.pathname === '/profile/projects' && 'active')
                                }
                                to='/profile/projects'
                              >
                                <span style={{color: 'black'}}>
                                  {intl.formatMessage({id: 'ADDRESSES.NOADDRESS_TEXT'})}
                                </span>
                                {intl.formatMessage({id: 'ADDRESSES.NOADDRESS_HERE'})}
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}

              {!noAddresses && (
                <tbody>
                  <tr>
                    <td colSpan={6}>
                      <div className='card mb-5 mb-xl-10'>
                        <div className='card-body py-3'>
                          <div className='d-flex justify-content-center flex-column align-items-center'>
                            <img
                              className='mt-5'
                              style={{height: '20%', width: '5%'}}
                              src='/media/background/spinnerTable.gif'
                            ></img>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}

              {hasAddresses && addresses.length > 0 && (
                <tbody>
                  {addresses.map((address: any) => {
                    return (
                      <tr key={address.id}>
                        <td className={mobileClass}>
                          <label className='lbl'>
                            {intl.formatMessage({id: 'ADDRESSES.TABLE_STREET'})}
                          </label>

                          <div className={mobileClassCol}>
                            <div className='d-flex justify-content-start flex-column'>
                              {address.streetName} {address.streetNumber}
                            </div>
                          </div>
                        </td>
                        <td className={mobileClass} style={{border: 'none'}}>
                          <label className='lbl'>
                            {intl.formatMessage({id: 'ADDRESSES.TABLE_FLOOR'})}
                          </label>

                          <div
                            className={
                              window.innerWidth > 500
                                ? mobileClassCol + ' justify-content-center'
                                : mobileClassCol
                            }
                          >
                            <div className='d-flex justify-content-start flex-column alignCenter'>
                              {address.floor}
                            </div>
                          </div>
                        </td>

                        {address.hasLift && (
                          <td
                            className={mobileClass}
                            style={{border: 'none', justifyContent: 'center'}}
                          >
                            <label className='lbl'>
                              {intl.formatMessage({id: 'ADDRESSES.TABLE_LIFT'})}
                            </label>

                            <div
                              className={
                                window.innerWidth > 500
                                  ? mobileClassCol + ' justify-content-center'
                                  : mobileClassCol
                              }
                            >
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='badge badge-success'>
                                  {intl.formatMessage({id: 'ADDRESSES.LIFT_YES'})}
                                </span>
                              </div>
                            </div>
                          </td>
                        )}
                        {!address.hasLift && (
                          <td className={mobileClass} style={{border: 'none'}}>
                            <label className='lbl'>
                              {intl.formatMessage({id: 'ADDRESSES.TABLE_LIFT'})}
                            </label>

                            <div
                              className={
                                window.innerWidth > 500
                                  ? mobileClassCol + ' justify-content-center'
                                  : mobileClassCol
                              }
                            >
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='badge badge-danger'>
                                  {intl.formatMessage({id: 'ADDRESSES.LIFT_NO'})}
                                </span>
                              </div>
                            </div>
                          </td>
                        )}
                        <td className={mobileClass}>
                          <label className='lbl'>
                            {intl.formatMessage({id: 'ADDRESSES.TABLE_CITY'})}
                          </label>

                          <div className={mobileClassCol}>
                            <div className='d-flex justify-content-start flex-column'>
                              {address.city.name}
                            </div>
                          </div>
                        </td>
                        <td className={mobileClass}>
                          <label className='lbl'>
                            {intl.formatMessage({id: 'ADDRESSES.TABLE_ZIP'})}
                          </label>

                          <div
                            className={
                              window.innerWidth > 500
                                ? mobileClassCol + ' justify-content-center'
                                : mobileClassCol
                            }
                          >
                            <div className='d-flex justify-content-start flex-column'>
                              {address.zipPostalCode}
                            </div>
                          </div>
                        </td>
                        <td className={mobileClass}>
                          <label className='lbl'>
                            {intl.formatMessage({id: 'ADDRESSES.TABLE_STATE'})}
                          </label>

                          <div className={mobileClassCol}>
                            <div className='d-flex justify-content-start flex-column'>
                              {address.country.name}
                            </div>
                          </div>
                        </td>
                        {/* <td className="py-3 align-middle address">
                                 {address.addressType == 1 &&
                                <span className="align-center badge bg-success m-2 font-weight-bold text-light">Kuća</span>
                                 }
                                  {address.addressType == 2 &&
                                <span className="align-center badge bg-info m-2 font-weight-bold text-light">Posao</span>
                                 }
                                  {address.addressType == 3 &&
                                <span className="align-center badge bg-danger m-2 font-weight-bold text-light">Ostalo</span>
                                 }
                        </td> 
                         */}
                        {isEditAddress != null && (
                          <td className={mobileClass}>
                            <label className='lbl'>
                              {intl.formatMessage({id: 'ORDERS.TABLE_ACTIONS'})}
                            </label>
                            <div className={mobileClassCol}>
                              <a
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                type='button'
                                onClick={() => editAddressHandler(address)}
                                title='Edit'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='svg-icon-3'
                                />
                              </a>
                              <a
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                type='button'
                                data-bs-toggle='dropdown'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-3'
                                />
                              </a>
                              <ul
                                className='dropdown-menu text-center'
                                aria-labelledby='dropdownMenuButton1'
                              >
                                <h6 className=''>Da li ste sigurni?</h6>
                                <hr className='bg-danger border-2 border-top border-danger'></hr>
                                <li>
                                  <a
                                    className='dropdown-item'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => removeAddressHandler(address.id)}
                                  >
                                    Da
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className='dropdown-item'
                                    data-bs-toggle='dropdown'
                                    style={{cursor: 'pointer'}}
                                  >
                                    Ne
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
