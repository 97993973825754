/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useSearchParams, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login, signUpConfirmation} from '../redux/AuthCRUD'
import {useIntl} from 'react-intl'
import {AppTheme} from '../../../models/AppThemeModel'
import {getAppTheme} from '../../../helpers/helpers'

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [searchParams, setSearchParams] = useSearchParams()
  const intl = useIntl()
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${intl.formatMessage({id: 'VALIDATOR.FORMAT_EMAIl'})}`)
      .min(
        8,
        `${intl.formatMessage({id: 'VALIDATOR.MINIMUM'})} 8 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .max(
        30,
        `${intl.formatMessage({id: 'VALIDATOR.MAXIMUM'})}30 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .required(`${intl.formatMessage({id: 'VALIDATOR.FORMAT_EMAIlREQUIRED'})}`),
    password: Yup.string()
      .min(
        8,
        `${intl.formatMessage({id: 'VALIDATOR.MINIMUM'})} 8 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .max(
        15,
        `${intl.formatMessage({id: 'VALIDATOR.MAXIMUM'})}15 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .required(`${intl.formatMessage({id: 'VALIDATOR.PASSWORD_PWREQUIRED'})}`),
  })
  const [confirmCodeBool, setConfirmCodeBool] = useState(false)
  const [confirmCode, setConfirmCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [isRedirect, setIsRedirect] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState('')
  const [theme, setTheme] = useState<AppTheme>()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      login(values.email, values.password)
        .then((response) => {
          const date = new Date()
          const seconds = Math.floor(date.getTime() / 1000)
          setLoading(false)
          localStorage.setItem('session-token', JSON.stringify(response.data))
          if (isRedirect == false && response.status == 200) {
            dispatch(auth.actions.login(response.data.accessToken))
            localStorage.setItem(
              'emaq-refreshToken',
              JSON.stringify(response.data.refreshTokenExpiresIn + seconds)
            )
          }
          if (isRedirect == true && response.status == 200) {
            var redirect = localStorage?.getItem('redirectUrl')
            if (redirect != null) {
              localStorage.setItem(
                'emaq-refreshToken',
                JSON.stringify(response.data.refreshTokenExpiresIn + seconds)
              )
              window.location.href = redirect
              dispatch(auth.actions.login(response.data.accessToken))
            }
          }
        })
        .catch((error) => {
          setLoading(false)
          setSubmitting(false)
          let msg = error.response.data['users.AccessCredentials'][0]
          if (msg == 'Email or password you entered is not valid!')
            setStatus('Pogrešno korisničko ime i/ili lozinka.')
        })
    },
  })

  const confirmRegistration = () => {
    signUpConfirmation(confirmCode).then((response) => {
      if (response.data == true) {
        setHasErrors(false)
        var url = searchParams.get('redirectTo')
        if (url != null) {
          localStorage.setItem('redirectUrl', url)
          navigate(`/auth?redirectTo=${url}`)
          setIsRedirect(true)
          setRedirectUrl(url)
        } else {
          navigate('/')
          localStorage.removeItem('redirectUrl')
        }
        setConfirmCodeBool(false)
      }
    })
  }

  useEffect(() => {
    localStorage.removeItem('redirectUrl')
    var url = searchParams.get('redirectTo')
    if (url != null) {
      localStorage.setItem('redirectUrl', url)
      setIsRedirect(true)
      setRedirectUrl(url)
    } else if (url == null) {
      localStorage.removeItem('redirectUrl')
    }
    setIsRedirect(searchParams.get('redirectTo') != null ? true : false)
    var data = {
      confirmCode: searchParams.get('confirmCode'),
    }
    if (data.confirmCode != null) {
      setConfirmCode(data.confirmCode)
      setConfirmCodeBool(true)
    }
    setTheme(getAppTheme())
  }, [])

  return (
    <>
      {!confirmCodeBool && (
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {/* begin::Heading */}
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'> {intl.formatMessage({id: 'AUTH.LOGIN.TITLE'})}</h1>
            <div className='text-gray-400 fw-bold fs-4'>
              {intl.formatMessage({id: 'AUTH.GENERAL.NO_ACCOUNT'})}{' '}
              <Link
                to='/auth/registration'
                style={{color: `${theme?.fontColor!}`, fontWeight: 'bolder'}}
              >
                {intl.formatMessage({id: 'AUTH.LOGIN.REGISTER'})}{' '}
              </Link>
            </div>
          </div>
          {/* begin::Heading */}

          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            <div></div>
          )}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}{' '}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
              tabIndex={1}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                  {intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
                </label>
                {/* end::Label */}
                {/* begin::Link */}

                {/* end::Link */}
              </div>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                {/* end::Label */}
                {/* begin::Link */}
                <Link
                  to='/auth/forgot-password'
                  className='link-primary fs-6 '
                  style={{color: `${theme?.fontColor!}`, marginLeft: '5px'}}
                >
                  {intl.formatMessage({id: 'AUTH.GENERAL.FORGOT_BUTTON'})}
                </Link>
                {/* end::Link */}
              </div>
            </div>
            <input
              type='password'
              autoComplete='off'
              tabIndex={2}
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              tabIndex={3}
              className='btn btn-lg  w-100 mb-5'
              style={{backgroundColor: `${theme?.fontColor!}`, color: 'white'}}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

            {/* begin::Separator */}
            {/* <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div>*/}
            {/* end::Separator */}

            {/* begin::Google link */}
            {/*<a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Prijavi se putem Google
        </a>
        {/* end::Google link */}

            {/* begin::Google link */}
            {/*<a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Prijavi se putem Facebook
          </a>*/}
            {/* end::Google link */}

            {/* begin::Google link */}
            {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Prijavi se putem Apple
        </a>*/}
            {/* end::Google link */}
          </div>
          {/* end::Action */}
        </form>
      )}

      {confirmCodeBool && (
        <div>
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>
              {intl.formatMessage({id: 'REGISTRATIONCONFIRM.TITLE'})}
            </h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-bold fs-4'>
              {intl.formatMessage({id: 'REGISTRATIONCONFIRM.TEXT'})}
            </div>
            {/* end::Link */}
          </div>
          {hasErrors === false && (
            <div className='mb-lg-15 alert alert-success'>
              <div className='alert-text font-weight-bold'>
                {intl.formatMessage({id: 'REGISTRATIONCONFIRM.SUCCESS'})}
              </div>
            </div>
          )}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <Link to='/auth/login'>
              <button
                type='submit'
                onClick={confirmRegistration}
                id='kt_password_reset_submit'
                className='btn btn-lg  fw-bolder me-4'
                style={{backgroundColor: `${theme?.fontColor!}`, color: 'white'}}
              >
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'REGISTRATIONCONFIRM.CONFIRM'})}
                </span>
                {loading && (
                  <span className='indicator-progress'>
                    Prijavite se
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </Link>
          </div>
        </div>
      )}
    </>
  )
}
