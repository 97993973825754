import React from 'react'
import clsx from 'clsx'
import {HashRouter, Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'

type Props = {
  to?: string
  title: string
  icon?: string
  hasBullet?: boolean
  isAction?: boolean
  isNav?: boolean
}

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  isNav,
  icon,
  hasBullet,
  isAction = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to!)
  const {config} = useLayout()
  const {aside} = config
  return (
    <>
      {isNav && (
        <div className='menu-item'>
          <Link className={clsx('menu-link', {active: isActive})} to={to!}>
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}
            {isAction && (
              <div className='symbol symbol-25px me-5'>
                <img src={toAbsoluteUrl(icon!)} className='' alt='' />
              </div>
            )}
            {!isAction && (
              <div className='symbol symbol-25px me-5'>
                <img src={toAbsoluteUrl(icon!)} className='' alt='' />
              </div>
            )}
            {isAction && <span className='menu-title'>{title}</span>}
            {!isAction && <span className='menu-title fs-4'>{title}</span>}
          </Link>
          {children}
        </div>
      )}
      {!isNav && (
        <div className='menu-item'>
          <a
            className={clsx('menu-link', {active: isActive})}
            onClick={() => (window.location.href = 'https://' + to!)}
          >
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}
            {isAction && (
              <div className='symbol symbol-25px me-5'>
                <img src={toAbsoluteUrl(icon!)} className='' alt='' />
              </div>
            )}
            {!isAction && (
              <div className='symbol symbol-25px me-5'>
                <img src={toAbsoluteUrl(icon!)} className='' alt='' />
              </div>
            )}
            {isAction && <span className='menu-title fs-4'>{title}</span>}
            {!isAction && <span className='menu-title fs-4'>{title}</span>}
          </a>
          {children}
        </div>
      )}
    </>
  )
}

export {AsideMenuItem}
