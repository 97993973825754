/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage, getLanguage} from '../../../i18n/Metronici18n'

import data from '../../../../demodata/quickactions.json'
import {getQuickActions} from '../../../../app/services/AddressesServices'

type Props = {
  className: string
}

function QuickActions(className: any) {
  const [quickActions, setQuickActions] = useState([])
  const [loading, setLoading] = useState(false)
  // getQuickActions()
  //   .then((response) => {
  //     if (response.status == 200) {
  //       setQuickActions(response.data)
  //       setLoading(false)
  //     }
  //   })
  //   .catch((error) => {
  //     console.log(error)
  //   })
const language = getLanguage();
var quickActionsData= data.tenants.filter( function(item){return (item.language==language);} );
  return (
    <>
      {loading && (
        <div className={`card ${className}`}>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body py-3'>
              <div className='d-flex justify-content-center flex-column align-items-center'>
                <img
                  className='mt-5'
                  style={{height: '20%', width: '5%'}}
                  src='/media/background/spinnerTable.gif'
                ></img>
              </div>
            </div>
          </div>
        </div>
      )}

      {!loading &&
        quickActionsData[0].applications[0].quickActions.map((nesto,index) => {
          return (
            <a href={nesto.ActionUrl} className='col-xl-4' key={nesto.Id}>
              <div className={`card ${className}`}>
                {/* <span
                  className='position-absolute top-0 start-75 translate-middle badge rounded-pill bg-danger w-10 d-flex align-items-center justify-content-center'
                  style={{marginLeft: '40px', minWidth: '10%'}}
                >
                  {nesto.EmotionalTrigger.toString()}
                </span> */}
                {/* begin::Body */}
                <div className='card-body d-flex align-items-center pt-3 pb-0'>
                  <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
                    <p className='fw-bolder text-dark fs-5 mb-2 text-hover-primary'>
                      {nesto.Name}
                    </p>
                    <span className='fw-bold text-muted fs-7 text-hover-primary'>
                      {data.tenants[index].Name}
                    </span>
                  </div>
                  <img
                    src={toAbsoluteUrl(nesto.Icon)}
                    alt=''
                    className='align-self-end h-100px w-90px mb-3'
                  />
                </div>
              </div>
            </a>
          )
        })}
    </>
  )
}

export {QuickActions}
