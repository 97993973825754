import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {addAddress, getCities, getCountries} from '../../../services/AddressesServices'
import {useIntl} from 'react-intl'
import {AppTheme} from '../../../models/AppThemeModel'
import {getAppTheme} from '../../../helpers/helpers'

export function AddAddress(props: any) {
  const [hasLift, setHasLift] = useState(false)

  const [country, setCountry] = useState(-1)
  const [theme, setTheme] = useState<AppTheme>()
  const [city, setCity] = useState(-1)
  const intl = useIntl()

  const addressesSchema = Yup.object().shape({
    streetName: Yup.string()
      .min(
        3,
        `${intl.formatMessage({id: 'VALIDATOR.MINIMUM'})} 3 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .max(
        25,
        `${intl.formatMessage({id: 'VALIDATOR.MAXIMUM'})}25 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .required(`${intl.formatMessage({id: 'VALIDATOR.FORMAT_STREETREQUIRED'})}`),
    streetNumber: Yup.string()
      .min(
        1,
        `${intl.formatMessage({id: 'VALIDATOR.MINIMUM'})} 1 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .max(
        4,
        `${intl.formatMessage({id: 'VALIDATOR.MAXIMUM'})}4 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .required(`${intl.formatMessage({id: 'VALIDATOR.FORMAT_STREETNUMBERREQUIRED'})}`),
    city: Yup.string().required(`${intl.formatMessage({id: 'VALIDATOR.FORMAT_CITYREQUIRED'})}`),
    country: Yup.string().required(
      `${intl.formatMessage({id: 'VALIDATOR.FORMAT_COUNTRYREQUIRED'})}`
    ),
    zipPostalCode: Yup.string()
      .min(
        3,
        `${intl.formatMessage({id: 'VALIDATOR.MINIMUM'})} 3 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .max(
        25,
        `${intl.formatMessage({id: 'VALIDATOR.MAXIMUM'})}25 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .required(`${intl.formatMessage({id: 'VALIDATOR.FORMAT_ZIPREQUIRED'})}`),
  })

  const [cities, setCities] = useState([
    {
      id: 0,
      name: '',
      countryId: 0,
    },
  ])
  const [countries, setCountries] = useState([
    {
      id: 0,
      name: '',
    },
  ])
  var initialValues = {
    streetName: '',
    streetNumber: '',
    floor: '',
    country: country,
    city: city,
    zipPostalCode: '',
    addressType: '',
    hasLift: '',
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addressesSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      var data = localStorage.getItem('persist:emaq-usersession')
      var userData = JSON.parse(data!)
      var user = JSON.parse(userData.user)
      var address = {
        streetName: values.streetName,
        streetNumber: values.streetNumber,
        zipPostalCode: values.zipPostalCode,
        addressType: 3,
        UserId: user.userId,
        isDefault: true,
        cityId: values.city,
        countryId: values.country,
        floor: values.floor,
        hasLift: hasLift,
      }
      setHasLift(false)
      resetForm()
      addAddress(address)
        .then((response) => {
          props.addressHandler()
        })
        .catch((err) => {
          console.log(err)
        })
    },
  })
  useEffect(() => {
    getCountries()
      .then((response) => {
        setCountries(response.data.resultList)
      })
      .catch((err) => {
        console.log(err)
      })
    getCities()
      .then((response) => {
        var filteredCities
        filteredCities = response.data.resultList.filter((x: any) => x.countryId == 1)
        setCities(filteredCities)
      })
      .catch((err) => {
        console.log(err)
      })
    setTheme(getAppTheme())
  }, [])

  function filterCities(e: any) {
    setCountry(e.target.value)
    getCities().then((response) => {
      var countryId = e.target.value
      setCountry(countryId)
      var filteredCities
      filteredCities = response.data.resultList.filter((x: any) => x.countryId == countryId)
      setCities(filteredCities)
    })
  }

  function handleCity(e: any) {
    setCity(e.target.value)
  }

  return (
    <>
      <div className='col-12'>
        <form className='form' onSubmit={formik.handleSubmit}>
          <div className='card-body'>
            <div id='kt_repeater_1'>
              <div className='form-group row' id='kt_repeater_1'>
                <div data-repeater-list='' className='col-lg-9'>
                  <div data-repeater-item className='form-group row align-items-center'>
                    {/* <div className="col-md-2">
                                <select
                                 {...formik.getFieldProps('addressType')}
                                 className={clsx(
                                   'form-select form-control-lg form-control-solid',
                                   {'is-invalid': formik.touched.addressType && formik.errors.addressType},
                                   {
                                     'is-valid': formik.touched.addressType && !formik.errors.addressType,
                                   }
                                 )} 
                                 name="addressType"
                                  id="addressType"
                                   placeholder="Type">
                                   <option value="" disabled  hidden>Tip adrese</option>
                                    <option value="1">Kuća</option>
                                    <option value="2">Posao</option>
                                    <option value="3">Ostalo</option>
                                </select>
                                <div className="d-md-none mb-2"></div>
                            </div> */}

                    <div className='col-md-4'>
                      <select
                        {...formik.getFieldProps('country')}
                        className={clsx(
                          'form-select form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.country && formik.errors.country},
                          {
                            'is-valid': formik.touched.country && !formik.errors.country,
                          }
                        )}
                        name='country'
                        id='country'
                        placeholder={intl.formatMessage({id: 'ADDRESSES.ADD.INPUT_STATE'})}
                        onChange={filterCities}
                        value={country}
                      >
                        <option value='-1' disabled selected>
                          {intl.formatMessage({id: 'ADDRESSES.ADD.INPUT_STATE'})}
                        </option>

                        {countries.map((country, key) => {
                          return (
                            <option key={country.id} value={country.id}>
                              {country.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div className='col-md-4 mt-2 mt-md-0'>
                      <select
                        {...formik.getFieldProps('city')}
                        className={clsx(
                          'form-select form-control-lg form-control-solid',
                          {'is-invalid': formik.errors.city && formik.touched.city},
                          {
                            'is-valid': formik.touched.city && !formik.errors.city,
                          }
                        )}
                        name='city'
                        id='city'
                        placeholder={intl.formatMessage({id: 'ADDRESSES.ADD.INPUT_CITY'})}
                        onChange={handleCity}
                        value={city}
                      >
                        <option value='-1' disabled selected>
                          {intl.formatMessage({id: 'ADDRESSES.ADD.INPUT_CITY'})}
                        </option>
                        {cities.map((city, key) => {
                          return (
                            <option key={city.id} value={city.id}>
                              {city.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div className='col-md-3 mt-2 mt-md-0'>
                      <input
                        {...formik.getFieldProps('zipPostalCode')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid':
                              formik.touched.zipPostalCode && formik.errors.zipPostalCode,
                          },
                          {
                            'is-valid':
                              formik.touched.zipPostalCode && !formik.errors.zipPostalCode,
                          }
                        )}
                        type='text'
                        name='zipPostalCode'
                        placeholder={intl.formatMessage({id: 'ADDRESSES.ADD.INPUT_ZIP'})}
                      />
                      <div className='d-md-none mb-2'></div>
                    </div>
                  </div>

                  <div
                    data-repeater-item
                    className='form-group row align-items-center mt-0 mt-md-5'
                  >
                    <div className='col-md-4'>
                      <input
                        {...formik.getFieldProps('streetName')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.streetName && formik.errors.streetName},
                          {
                            'is-valid': formik.touched.streetName && !formik.errors.streetName,
                          }
                        )}
                        type='text'
                        name='streetName'
                        placeholder={intl.formatMessage({id: 'ADDRESSES.ADD.INPUT_STREET'})}
                      />
                      <div className='d-md-none mb-2'></div>
                    </div>

                    <div className='col-md-2'>
                      <input
                        {...formik.getFieldProps('streetNumber')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.streetNumber && formik.errors.streetNumber},
                          {
                            'is-valid': formik.touched.streetNumber && !formik.errors.streetNumber,
                          }
                        )}
                        type='number'
                        name='streetNumber'
                        placeholder={intl.formatMessage({id: 'ADDRESSES.ADD.INPUT_STEETNUMBER'})}
                      />
                      <div className='d-md-none mb-2'></div>
                    </div>

                    <div className='col-md-3'>
                      <input
                        {...formik.getFieldProps('floor')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.floor && formik.errors.floor},
                          {
                            'is-valid': formik.touched.floor && !formik.errors.floor,
                          }
                        )}
                        type='number'
                        name='floor'
                        placeholder={intl.formatMessage({id: 'ADDRESSES.ADD.INPUT_FLOOR'})}
                      />
                      <div className='d-md-none mb-2'></div>
                    </div>

                    <div className='col-md-2 btn-group mt-1 mt-md-0'>
                      <div className='form-check'>
                        <input
                          {...formik.getFieldProps('hasLift')}
                          className='form-check-input'
                          checked={hasLift}
                          onClick={() => (hasLift == true ? setHasLift(false) : setHasLift(true))}
                          type='checkbox'
                          id='lift'
                        />
                        <label className='form-check-label' htmlFor='lift'>
                          {intl.formatMessage({id: 'ADDRESSES.ADD.INPUT_LIFT'})}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  data-repeater-list=''
                  className='col-lg-3 form-group row align-items-center mt-0  d-flex align-items-center'
                >
                  <div className='col-md-12 btn-group mt-5 mt-md-0 '>
                    <button
                      onClick={() => {
                        formik.resetForm()
                        setCity(-1)
                        setCountry(-1)
                      }}
                      type='button'
                      className='btn btn-sm font-weight-bolder btn-danger'
                    >
                      <i className='la la-trash-o'></i>
                      {intl.formatMessage({id: 'ADDRESSES.ADD.BUTTON_CLEAR'})}
                    </button>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-sm font-weight-bolder'
                      style={{backgroundColor: `${theme?.fontColor!}`, color: 'white'}}
                    >
                      <i className='la la-check' style={{color: 'white'}}></i>
                      {intl.formatMessage({id: 'ADDRESSES.ADD.BUTTON_SAVE'})}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
