/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import {register} from '../redux/AuthCRUD'
import {Link} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useNavigate} from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {useIntl} from 'react-intl'
import {AppTheme} from '../../../models/AppThemeModel'
import {getAppTheme} from '../../../helpers/helpers'

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState('387')
  const [theme, setTheme] = useState<AppTheme>()
  const dispatch = useDispatch()
  const intl = useIntl()

  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    brojTelefona: state,
    acceptTerms: false,
  }

  const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(
        3,
        `${intl.formatMessage({id: 'VALIDATOR.MINIMUM'})} 3 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .max(
        20,
        `${intl.formatMessage({id: 'VALIDATOR.MAXIMUM'})}20${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .required(`${intl.formatMessage({id: 'VALIDATOR.FORMAT_NAMEREQUIRED'})}`),
    email: Yup.string()
      .email(`${intl.formatMessage({id: 'VALIDATOR.FORMAT_EMAIl'})}`)
      .max(
        30,
        `${intl.formatMessage({id: 'VALIDATOR.MAXIMUM'})}30 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .required(`${intl.formatMessage({id: 'VALIDATOR.FORMAT_EMAIlREQUIRED'})}`),
    brojTelefona: Yup.string()

      .min(11, `${intl.formatMessage({id: 'PHONENUMBER.SIZE_TEXT'})}`)
      .required(`${intl.formatMessage({id: 'VALIDATOR.FORMAT_NUMBEREQUIRED'})}`),
    lastname: Yup.string()
      .min(
        3,
        `${intl.formatMessage({id: 'VALIDATOR.MINIMUM'})} 3 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .max(
        20,
        `${intl.formatMessage({id: 'VALIDATOR.MAXIMUM'})}20${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .required(`${intl.formatMessage({id: 'VALIDATOR.FORMAT_LASTNAMEREQUIRED'})}`),
    password: Yup.string()
      .min(
        8,
        `${intl.formatMessage({id: 'VALIDATOR.MINIMUM'})} 8 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .max(
        15,
        `${intl.formatMessage({id: 'VALIDATOR.MAXIMUM'})}15 ${intl.formatMessage({
          id: 'VALIDATOR.CHARACTERS',
        })} `
      )
      .matches(
        /^(?=.*[a-z])(?=.*[0-9])/,
        intl.formatMessage({id: 'AUTH.REGISTER.PASSWORD_STRENGTH_TEXT'})
      )
      .required(`${intl.formatMessage({id: 'VALIDATOR.PASSWORD_PWREQUIRED'})}`),
    passwordConfirmation: Yup.string()
      .required(`${intl.formatMessage({id: 'VALIDATOR.PASSWORD_CONFIRM'})}`)
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          `${intl.formatMessage({id: 'VALIDATOR.PASSWORD_MISSMATCH'})}`
        ),
      }),
  })

  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      register(
        values.email,
        values.firstname,
        values.lastname,
        values.password,
        values.passwordConfirmation,
        state.toString()
      )
        .then(({data: {api_token}}) => {
          setStatus(intl.formatMessage({id: 'REGISTRATION.SUCCESS'}))
          dispatch(auth.actions.register(api_token))
          setLoading(false)

          setTimeout(() => {
            navigate('/')
          }, 4000)
        })
        .catch((err) => {
          if (err.response.data.email[0] == 'Email is already in use!') {
            setStatus(intl.formatMessage({id: 'REGISTRATION.EMAIL_IN_USE'}))
          } else {
            setStatus(intl.formatMessage({id: 'REGISTRATION.FAILED'}))
          }
          setLoading(false)
          setSubmitting(false)
        })
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    setTheme(getAppTheme())
  }, [])

  function changePhoneNumber(e: any) {
    formik.touched.brojTelefona = true
    if (e.length == 0) {
      formik.touched.brojTelefona = true

      formik.errors.brojTelefona = intl.formatMessage({id: 'VALIDATOR.FORMAT_NUMBEREQUIRED'})
    } else if (e.length > 0 && e.length < 11) {
      formik.errors.brojTelefona = intl.formatMessage({id: 'PHONENUMBER.SIZE_TEXT'})
    } else if (e.length >= 11 && e.length < 15) {
      formik.touched.brojTelefona = true
      formik.errors.brojTelefona = undefined
    }
    formik.values.brojTelefona = e
    setState(e)
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      autoComplete='off'
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'> {intl.formatMessage({id: 'AUTH.REGISTER.TITLE'})}</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          {intl.formatMessage({id: 'AUTH.REGISTER.HAS_ACCOUNT'})}
          <Link
            to='/auth/login'
            className='link-primary fw-bolder'
            style={{color: `${theme?.fontColor!}`, fontWeight: 'bold', marginLeft: '10px'}}
          >
            {intl.formatMessage({id: 'AUTH.REGISTER.LOGIN'})}
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* begin::Action */}

      {/* end::Action */}
      {formik.status && (
        <div className='mb-lg-15 alert alert-primary'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'AUTH.REGISTER.NAME'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'AUTH.REGISTER.NAME'})}
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'AUTH.REGISTER.LASTNAME'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'AUTH.REGISTER.LASTNAME'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('lastname')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.lastname && formik.errors.lastname,
                },
                {
                  'is-valid': formik.touched.lastname && !formik.errors.lastname,
                }
              )}
            />
            {formik.touched.lastname && formik.errors.lastname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastname}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7' style={{marginTop: '-25px'}}>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'AUTH.REGISTER.PHONE_NUMBER'})}
        </label>
        <PhoneInput
          inputProps={{name: 'brojTelefona'}}
          {...formik.getFieldProps('brojTelefona')}
          buttonStyle={{border: 'none', borderRight: '1px solid lightgrey'}}
          inputStyle={{width: '100%', fontFamily: 'Poppins', fontWeight: '500'}}
          country={'ba'}
          regions={['europe']}
          inputClass={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.brojTelefona && formik.errors.brojTelefona},
            {
              'is-valid': formik.touched.brojTelefona && !formik.errors.brojTelefona,
            }
          )}
          masks={{ba: '(..) ...-...-.'}}
          onChange={changePhoneNumber}
        />
        {formik.touched.brojTelefona && formik.errors.brojTelefona && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.brojTelefona}</span>
            </div>
          </div>
        )}
      </div>

      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
          </label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted fs-7'>
          {intl.formatMessage({id: 'AUTH.REGISTER.PASSWORD_TEXT'})}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'AUTH.REGISTER.PASSWORD_CONFIRM'})}
        </label>
        <input
          type='password'
          placeholder={intl.formatMessage({id: 'AUTH.REGISTER.PASSWORD_CONFIRM'})}
          autoComplete='off'
          {...formik.getFieldProps('passwordConfirmation')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid':
                formik.touched.passwordConfirmation && formik.errors.passwordConfirmation,
            },
            {
              'is-valid':
                formik.touched.passwordConfirmation && !formik.errors.passwordConfirmation,
            }
          )}
        />
        {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.passwordConfirmation}</span>
            </div>
          </div>
        )}
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg  w-100 mb-5'
          style={{backgroundColor: `${theme?.fontColor!}`, color: 'white'}}
        >
          {!loading && (
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.REGISTER.SAVE'})}
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'AUTH.REGISTER.SAVE'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light w-100 mb-5'
          >
            {intl.formatMessage({id: 'AUTH.REGISTER.CANCEL'})}
            {loading && (
              <span className='indicator-progress'>
                Prijavite se
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
