import {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getUserOrdersDetails} from '../../../services/OrdersServices'
import {useIntl} from 'react-intl'

export function OrderDetails(props: any) {
  const [showDetails, setShowDetails] = useState(props.showModal)
  const [hasLoaded, setHasLoaded] = useState(false)
  const intl = useIntl()
  const [order, setOrder] = useState({
    id: 0,
    isFreeShipping: false,
    isPickupInStore: false,
    orderNumber: 0,
    shippingAddress: {
      id: 0,
      cityId: 0,
      countryId: 0,
      streetName: 'Envera Sehovica',
      streetNumber: '12',
    },
    orderItems: [
      {
        id: 0,
        discountPercentage: 0,
        quantity: 0,
        totalDiscountAmount: 0,
        totalPrice: 0,
        totalPriceTaxAmount: 0,
        totalPriceWithoutTax: 0,
        totalTaxPercentage: 0,
        unitDiscountAmount: 0,
        unitPrice: 0,
        unitPriceTaxAmount: 0,
        unitPriceWithoutTax: 0,

        item: {
          id: 0,
          code: '',
          images: [
            {
              id: 0,
              imageUrl: '',
            },
          ],
          itemTranslations: [
            {
              id: 0,
              languageId: 0,
              name: '',
            },
          ],
          category: {
            id: 0,
            categoryTranslations: [
              {
                id: 0,
                languageId: 0,
                name: '',
              },
            ],
          },
        },
      },
    ],
    orderStatus: {
      id: 0,
      statusColor: '',
      orderStatusTranslations: [
        {
          id: 0,
          languageId: 0,
          name: '',
        },
      ],
    },
    orderTax: 0,
    orderTotal: 0,
    paymentStatusId: 0,
    paymentType: {
      id: 0,
      paymentTypeTranslations: [
        {
          id: 0,
          languageId: 0,
          name: '',
        },
      ],
    },
    paymentTypeId: 0,
    pointsLogs: [
      {
        id: 0,
        points: 0,
        ratio: 0,
      },
    ],
    shippingCosts: 0,
    store: {
      id: 0,
      address: '',
    },
    tentantId: 0,
  })
  const handleClose = () => {
    setShowDetails(false)
    props.handler(false)
  }

  useEffect(() => {
    getUserOrdersDetails(props.order.id)
      .then((response) => {
        if (response.data != null) {
          setOrder(response.data)
          setHasLoaded(true)
        }
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      {hasLoaded && (
        <Modal
          show={showDetails}
          onHide={handleClose}
          size='xl'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton className='col-12'>
            <Modal.Title className='col-5'>
              {intl.formatMessage({id: 'ORDERS.DETAILS_TITLE'})} {' #' + order.orderNumber}
              <span
                className='badge'
                style={{backgroundColor: order.orderStatus.statusColor, marginLeft: '5%'}}
              >
                {order.orderStatus.orderStatusTranslations.find((x) => x.languageId == 1)?.name}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className='col-md-8 cart'>
                <div className='title'>
                  <div className='row'>
                    <div className='col-7'>
                      <h4>
                        <b>{intl.formatMessage({id: 'ORDERS.DETAILS_NAME'})}</b>
                      </h4>
                    </div>
                  </div>
                </div>
                {order.orderItems.map((orderItem, key) => {
                  return (
                    <div className='row border-top ' style={{marginTop: '5px'}} key={orderItem.id}>
                      <div className='row main align-items-center mt-5 mb-3'>
                        {orderItem.item.images.length == 0 && (
                          <div className='col-3'>
                            <div>
                              <img
                                loading='lazy'
                                style={{width: '100px', height: '100px', objectFit: 'contain'}}
                                src={toAbsoluteUrl('/media/logos/default.jpg')}
                                alt='Product image'
                              />
                            </div>
                          </div>
                        )}
                        {orderItem.item.images.length > 0 && (
                          <div className='col-3 d-flex justify-content-center'>
                            <div style={{width: '100px', height: '100px'}}>
                              <img
                                loading='lazy'
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'contain',
                                }}
                                src={orderItem.item.images[0]?.imageUrl}
                                alt='Product image'
                              />
                            </div>
                          </div>
                        )}

                        <div className='col-5'>
                          {orderItem.item.category == null && (
                            <div className='row text-muted'> </div>
                          )}
                          {orderItem.item.category?.categoryTranslations?.length > 0 && (
                            <div className='row text-muted'>
                              {
                                orderItem.item.category?.categoryTranslations.find(
                                  (x) => x.languageId == 1
                                )?.name
                              }
                            </div>
                          )}
                          {orderItem.item.itemTranslations.length == 0 && (
                            <div className='row'>
                              {' '}
                              {intl.formatMessage({id: 'ORDERS.DETAILS_NO_NAME'})}
                            </div>
                          )}
                          {orderItem.item.itemTranslations.length > 0 && (
                            <div className='row'>
                              {orderItem.item.itemTranslations.find((x) => x.languageId == 1)?.name}
                            </div>
                          )}
                        </div>
                        <div className='col-2 text-center' style={{color: 'blue'}}>
                          {' '}
                          {orderItem.quantity}x{' '}
                        </div>
                        <div className='col-2 text-end'>
                          {orderItem.unitPrice.toFixed(2)} KM<span className='close'></span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='col-md-4 summary'>
                <div>
                  <h5>
                    <b> {intl.formatMessage({id: 'ORDERS.DETAILS_ORDER_INFO'})}</b>
                  </h5>
                  <hr />
                  {order.isFreeShipping && (
                    <p>
                      {intl.formatMessage({id: 'ORDERS.DETAILS_ORDER_INFO_FREE'})}
                      <b style={{float: 'right'}}>{intl.formatMessage({id: 'GENERAL.YES'})}</b>
                    </p>
                  )}

                  <p>
                    {intl.formatMessage({id: 'ORDERS.DETAILS_ORDER_INFO_PICKUP'})}

                    <b style={{float: 'right'}}>
                      {order.isPickupInStore
                        ? intl.formatMessage({id: 'ORDERS.DETAILS_ORDER_INFO_PICKUP_STORE'})
                        : intl.formatMessage({id: 'ORDERS.DETAILS_ORDER_INFO_PICKUP_ONADDRESS'})}
                    </b>
                  </p>
                  {order.isPickupInStore && (
                    <p>
                      {intl.formatMessage({id: 'ORDERS.ORDER_ADDRESS_INFO'})}
                      <b style={{float: 'right'}}>{order.store.address}</b>
                    </p>
                  )}
                  {!order.isPickupInStore && order.shippingAddress != null && (
                    <p>
                      {intl.formatMessage({id: 'ORDERS.ORDER_ADDRESS_INFO'})}
                      <b style={{float: 'right'}}>
                        {order.shippingAddress?.streetName +
                          ' ' +
                          order.shippingAddress?.streetNumber}
                      </b>
                    </p>
                  )}
                </div>

                <div className='mt-20'>
                  <h5>
                    <b> {intl.formatMessage({id: 'ORDERS.DETAILS_ORDER_PAYMENT'})}</b>
                  </h5>
                  <hr />
                  <p>
                    {intl.formatMessage({id: 'ORDERS.DETAILS_ORDER_PAYMENT_TYPE'})}
                    <b style={{float: 'right'}}>
                      {
                        order.paymentType.paymentTypeTranslations.find((x) => x.languageId == 1)
                          ?.name
                      }{' '}
                    </b>
                  </p>
                  {!order.isFreeShipping && (
                    <p>
                      {intl.formatMessage({id: 'ORDERS.DETAILS_ORDER_PAYMENT_SHIPPING'})}

                      <b style={{float: 'right'}}>{order.shippingCosts.toFixed(2)} KM </b>
                    </p>
                  )}

                  <p>
                    {intl.formatMessage({id: 'ORDERS.DETAILS_ORDER_PAYMENT_ORDERTOTAL'})}

                    <b style={{float: 'right'}}>{order.orderTotal.toFixed(2)} KM </b>
                  </p>
                  <p>
                    {intl.formatMessage({id: 'ORDERS.DETAILS_ORDER_PAYMENT_TOTAL'})}

                    <b style={{float: 'right'}}>
                      {(order.orderTotal + order.shippingCosts).toFixed(2)} KM{' '}
                    </b>
                  </p>
                </div>
                {order.pointsLogs[0]?.points > 0 && (
                  <div className='mt-20'>
                    <h5>
                      <b> {intl.formatMessage({id: 'ORDERS.DETAILS_ORDER_LOYALTY'})}</b>
                    </h5>
                    <hr />

                    <p>
                      {intl.formatMessage({id: 'ORDERS.DETAILS_ORDER_POINTS_PAID'})}

                      <b style={{float: 'right'}}>
                        {' '}
                        {order.pointsLogs[0]?.points < 0
                          ? intl.formatMessage({id: 'GENERAL.YES'})
                          : intl.formatMessage({id: 'GENERAL.NO'})}
                      </b>
                    </p>
                    {order.pointsLogs[0]?.points > 0 && (
                      <p>
                        {intl.formatMessage({id: 'ORDERS.DETAILS_ORDER_POINTS_GAINED'})}
                        <b style={{float: 'right'}}>{order.pointsLogs[0].points} </b>{' '}
                      </p>
                    )}
                    {order.pointsLogs[0]?.points < 0 && (
                      <p>
                        {intl.formatMessage({id: 'ORDERS.DETAILS_ORDER_POINTS_SPENT'})}
                        <b style={{float: 'right'}}>{order.pointsLogs[0].points * -1} </b>{' '}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              {intl.formatMessage({id: 'ORDERS.DETAILS_ORDER_CLOSE'})}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}
